import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { FiDownload, FiCalendar, FiHardDrive, FiInfo, FiPackage, FiCode, FiShield } from 'react-icons/fi';

const SoftwareDownloadPage = () => {
  // モックデータを更新
  const mockCategories = [
    {
      id: 1,
      name: "LabVIEW",
      description: "LabVIEWアプリケーションとツール",
      files: [
        {
          id: 101,
          name: "LabVIEW Data Logger",
          description: "高速データ収集と解析のためのLabVIEWアプリケーション",
          versions: [
            { version: "2.1.0", release_date: "2024-03-15", file_size: "45.2 MB" },
            { version: "2.0.0", release_date: "2024-02-15", file_size: "44.8 MB" },
            { version: "1.9.0", release_date: "2024-01-15", file_size: "43.5 MB" }
          ]
        },
        {
          id: 102,
          name: "VI Analyzer",
          description: "LabVIEWコードの品質分析と最適化ツール",
          versions: [
            { version: "1.5.2", release_date: "2024-03-10", file_size: "32.8 MB" },
            { version: "1.5.1", release_date: "2024-02-10", file_size: "32.5 MB" },
            { version: "1.5.0", release_date: "2024-01-10", file_size: "32.0 MB" }
          ]
        }
      ]
    },
    {
      id: 2,
      name: "Python",
      description: "Pythonスクリプトとアプリケーション",
      files: [
        {
          id: 201,
          name: "Data Processing Tool",
          description: "大規模データ処理のためのPythonスクリプト集",
          versions: [
            { version: "3.0.1", release_date: "2024-03-01", file_size: "28.5 MB" },
            { version: "3.0.0", release_date: "2024-02-01", file_size: "28.0 MB" },
            { version: "2.9.9", release_date: "2024-01-01", file_size: "27.8 MB" }
          ]
        },
        {
          id: 202,
          name: "Analysis Framework",
          description: "科学計算とデータ分析のためのPythonフレームワーク",
          versions: [
            { version: "2.2.0", release_date: "2024-02-28", file_size: "15.7 MB" },
            { version: "2.1.0", release_date: "2024-01-28", file_size: "15.5 MB" },
            { version: "2.0.0", release_date: "2023-12-28", file_size: "15.0 MB" }
          ]
        }
      ]
    },
    {
      id: 3,
      name: "Android",
      description: "Androidアプリケーションとツール",
      files: [
        {
          id: 301,
          name: "Mobile Data Viewer",
          description: "計測データのモバイル表示アプリケーション",
          versions: [
            { version: "4.1.2", release_date: "2024-03-12", file_size: "22.3 MB" },
            { version: "4.1.1", release_date: "2024-02-12", file_size: "22.1 MB" },
            { version: "4.1.0", release_date: "2024-01-12", file_size: "22.0 MB" }
          ]
        },
        {
          id: 302,
          name: "Sensor Interface",
          description: "各種センサーとの連携用Androidアプリ",
          versions: [
            { version: "1.0.5", release_date: "2024-03-08", file_size: "18.6 MB" },
            { version: "1.0.4", release_date: "2024-02-08", file_size: "18.4 MB" },
            { version: "1.0.3", release_date: "2024-01-08", file_size: "18.2 MB" }
          ]
        }
      ]
    }
  ];

  const [categories] = useState(mockCategories);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedVersions, setSelectedVersions] = useState({});

  const handleVersionChange = (fileId, version) => {
    setSelectedVersions(prev => ({
      ...prev,
      [fileId]: version
    }));
  };

  useEffect(() => {
    // 初期値として各ファイルの最新バージョンを設定
    const initialVersions = {};
    categories.forEach(category => {
      category.files.forEach(file => {
        initialVersions[file.id] = file.versions[0].version;
      });
    });
    setSelectedVersions(initialVersions);
  }, []);

  const handleDownload = (fileId) => {
    const selectedVersion = selectedVersions[fileId];
    console.log(`Downloading file ID: ${fileId}, version: ${selectedVersion}`);
    alert(`ダウンロードを開始します（バージョン: ${selectedVersion}）`);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-white to-cyan-50">
      {/* ヒーローセクション - より青を強調 */}
      <div className="bg-gradient-to-r from-blue-600 to-cyan-600 text-white py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto text-center">
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              className="mb-8"
            >
              <h1 className="text-5xl font-bold mb-4">ソフトウェアダウンロード</h1>
              <p className="text-xl text-blue-100">
                最新のツールとアプリケーションを簡単にダウンロード
              </p>
            </motion.div>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-12">
        {error && (
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="bg-red-50 border-l-4 border-red-500 p-4 mb-8"
          >
            <p className="text-red-700">{error}</p>
          </motion.div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* カテゴリーサイドバー - 選択時の色を変更 */}
          <div className="md:col-span-1">
            <div className="bg-white rounded-lg shadow-lg p-6 sticky top-8">
              <h2 className="text-xl font-semibold mb-4 text-gray-800">カテゴリー</h2>
              <nav className="space-y-2">
                {categories.map(category => (
                  <motion.button
                    key={category.id}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() => setSelectedCategory(category.id)}
                    className={`w-full text-left px-4 py-3 rounded-lg transition-all flex items-center space-x-3 ${
                      selectedCategory === category.id
                        ? 'bg-gradient-to-r from-blue-500 to-cyan-500 text-white shadow-md'
                        : 'hover:bg-gray-50 text-gray-700'
                    }`}
                  >
                    {category.id === 1 && <FiCode className="w-5 h-5" />}
                    {category.id === 2 && <FiPackage className="w-5 h-5" />}
                    {category.id === 3 && <FiShield className="w-5 h-5" />}
                    <span>{category.name}</span>
                  </motion.button>
                ))}
              </nav>
            </div>
          </div>

          {/* メインコンテンツエリア */}
          <div className="md:col-span-3">
            {categories.map(category => (
              <motion.div
                key={category.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className={`mb-12 ${
                  selectedCategory && selectedCategory !== category.id ? 'hidden' : ''
                }`}
              >
                {/* カテゴリーヘッダー - 色を変更 */}
                <div className="bg-gradient-to-r from-blue-500 to-cyan-500 text-white rounded-lg p-6 mb-8">
                  <h2 className="text-3xl font-bold mb-2">{category.name}</h2>
                  <p className="text-blue-100">{category.description}</p>
                </div>
                
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6">
                  {category.files.map(file => (
                    <motion.div
                      key={file.id}
                      whileHover={{ y: -4 }}
                      className="bg-white rounded-xl shadow-lg overflow-hidden border border-gray-100 h-full flex flex-col"
                    >
                      <div className="p-6 flex flex-col flex-grow">
                        <h3 className="text-xl font-semibold mb-3 text-gray-800">{file.name}</h3>
                        <p className="text-gray-600 mb-6 flex-grow">{file.description}</p>
                        
                        <div className="mb-6">
                          <label className="block text-sm font-medium text-gray-700 mb-2">
                            バージョンを選択
                          </label>
                          <select
                            value={selectedVersions[file.id] || ''}
                            onChange={(e) => handleVersionChange(file.id, e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          >
                            {file.versions.map((ver) => (
                              <option key={ver.version} value={ver.version}>
                                v{ver.version}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="grid grid-cols-2 gap-4 mb-6">
                          <div className="flex items-center space-x-2 text-gray-600">
                            <FiCalendar className="w-5 h-5 text-blue-500" />
                            <span>
                              {new Date(file.versions.find(v => v.version === selectedVersions[file.id])?.release_date).toLocaleDateString()}
                            </span>
                          </div>
                          <div className="flex items-center space-x-2 text-gray-600">
                            <FiHardDrive className="w-5 h-5 text-cyan-500" />
                            <span>
                              {file.versions.find(v => v.version === selectedVersions[file.id])?.file_size}
                            </span>
                          </div>
                        </div>

                        {/* ダウンロードボタン - 色を変更 */}
                        <motion.button
                          whileHover={{ scale: 1.02 }}
                          whileTap={{ scale: 0.98 }}
                          onClick={() => handleDownload(file.id)}
                          className="w-full bg-gradient-to-r from-blue-500 to-cyan-500 hover:from-blue-600 hover:to-cyan-600 text-white font-semibold py-3 px-6 rounded-lg flex items-center justify-center space-x-2 transition-all shadow-md mt-auto"
                        >
                          <FiDownload className="w-5 h-5" />
                          <span>ダウンロード</span>
                        </motion.button>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoftwareDownloadPage;